<template>
    <Toast />
    <div class="p-grid">
        <div class="p-col-12">
            <div class="p-d-flex p-ai-center p-jc-between p-flex-column p-flex-lg-row p-mb-1">
                <h5>Plan Configuration</h5>
                <div class="p-text-capitalize"><h6>Plan Name : {{ this.plan_exit_label }} </h6></div>
                <div>
                    <Button label="Back" class="p-button-sm p-mr-2" @click="goToPlanList" icon="pi pi-chevron-left" />
                </div>
            </div>
            <div class="card">
                <DataTable :value="plancomponentlist" :lazy="true" :paginator="true" :rows="30" :totalRecords="totalRecords"
                    :loading="loading" @page="onPage($event)" class="p-datatable-users" data-key="bc1" :rowHover="true"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
                    <Toolbar>
                        <template v-slot:left>
                            <!-- <h5 class="p-m-0">{{ this.data.data.bc3 }}</h5> -->
                        </template>
                        <template v-slot:right>
                            <Button label="Add Plan Config" icon="pi pi-plus" class=" p-mr-2"
                                @click="addComponentDialogOpen" />
                        </template>
                    </Toolbar>
                    <template #empty> No data found. </template>
                    <template #loading> Loading data Please wait... </template>
                    <Column header="Sr." headerStyle="width: 4%">
                        <template #body="{ index }">
                            <div>{{ genrateSrNo(index) }}</div>
                        </template>
                    </Column>
                    <Column field="name" header="Component Name" headerStyle="width: 60%">
                        <template #body="{ data }">
                            <div class="p-text-capitalize">{{ data.component_name ? data.component_name : 'N/A' }}</div>
                        </template>
                    </Column>
                    <!-- <Column header="Free Count" headerStyle="width: 15%">
                        <template #body="{ data }">
                            <div class="p-text-capitalize">{{ data.included_free_value ? data.included_free_value : '0' }}
                            </div>
                        </template>
                    </Column> -->
                    <Column header="Extra Charges" headerStyle="width: 20%" >
                        <template #body="{ data }">
                            <div class="p-text-capitalize">&#8377; {{ formatCurrency(data.additional_rate ?
                                data.additional_rate : '0') }}</div>
                        </template>
                    </Column>
                    <Column header="Action" headerStyle="width: 10%" >
                        <template #body="{ data }">
                            <Button icon="pi pi-pencil" class="p-button-rounded " @click="editComponentDialogOpen(data)" />
                        </template>
                    </Column>
                </DataTable>
                <Toast />
            </div>
        </div>
    </div>
    <!-- add client dialog start here -->
    <Dialog v-model:visible="addComponentStatus" :style="{ width: '600px' }" header="Add Component" :modal="true"
        class="p-fluid">
        <div class="p-pt-2 p-px-3">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-12">
                    <div class="p-field-radiobutton">
                        <RadioButton id="apply_component" name="option" v-bind:value="1" v-model="add.componentstatus"
                            :checked="add.componentstatus == 1" />
                        <label for="apply_component" class="p-mr-2">Apply this component to existing clients</label>
                        <RadioButton id="not-apply-component" name="option" v-bind:value="2" v-model="add.componentstatus"
                            :checked="add.componentstatus == 2" />
                        <label for="not-apply-component" class="p-mr-2">Do not apply</label>
                    </div>
                    <small class="p-invalid p-error" v-if="v$.add.componentstatus.$error">{{
                        v$.add.componentstatus.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-12">
                    <label for="componentname">
                        Select Component
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <Dropdown id="componentname" v-model="add.componentname" :options="componentList" optionLabel="label"
                        placeholder="Select ..." appendTo="body"></Dropdown>
                    <small class="p-invalid p-error" v-if="v$.add.componentname.$error">{{
                        v$.add.componentname.$errors[0].$message
                    }}</small>
                </div>
                <!-- <div class="p-field p-col-12 p-md-6">
                    <label for="freecount">
                        Free Count
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <InputText id="freecount" class="p-text-capitalize" v-model.trim="add.freecount" required="true"
                        @keypress="onlyNumber" maxlength="8" autofocus
                        :class="{ 'p-invalid': submitted && !add.freecount }" />
                    <small class="p-invalid p-error" v-if="v$.add.freecount.$error">{{
                        v$.add.freecount.$errors[0].$message
                    }}</small>
                </div> -->
                <div class="p-field p-col-12 p-md-6">
                    <label for="extracharges">
                        Extra Charges
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <InputText id="extracharges" class="p-text-capitalize" v-model.trim="add.extracharges" required="true"
                        @keypress="onlyNumber" maxlength="8" autofocus
                        :class="{ 'p-invalid': submitted && !add.extracharges }" />
                    <small class="p-invalid p-error" v-if="v$.add.extracharges.$error">{{
                        v$.add.extracharges.$errors[0].$message
                    }}</small>
                </div>
            </div>
        </div>
        <template #footer>
            <div>
                <Button v-if="!showLoader" label="Submit" icon="pi pi-check" class="p-button-text p-ml-auto"
                    @click="addPlanComponent" style="width: 90px"></Button>
                <Button v-show="showLoader" icon="pi pi-spin pi-spinner indigo-color" class="p-button-text p-ml-auto"
                    style="width: 90px"></Button>
            </div>
        </template>
    </Dialog>
    <!-- add client dialog end here -->
    <!-- edit client dialog start here -->
    <Dialog v-model:visible="editComponentStatus" :style="{ width: '600px' }" header="Edit Component" :modal="true"
        class="p-fluid">
        <div class="p-pt-2 p-px-3">
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-12">
                    <div class="p-field-radiobutton">
                        <RadioButton id="apply_component" name="option" v-bind:value="1" v-model="edit.componentstatus"
                            :checked="edit.componentstatus == 1" />
                        <label for="apply_component" class="p-mr-2">Apply this component to existing clients</label>
                        <RadioButton id="not-apply-component" name="option" v-bind:value="2" v-model="edit.componentstatus"
                            :checked="edit.componentstatus == 2" />
                        <label for="not-apply-component" class="p-mr-2">Do not apply</label>
                    </div>
                    <small class="p-invalid p-error" v-if="v$.edit.componentstatus.$error">{{
                        v$.edit.componentstatus.$errors[0].$message
                    }}</small>
                </div>
                <div class="p-field p-col-12 p-md-12">
                    <label for="componentname">
                        Component Name 
                        <!-- <span class="p-invalid p-error">*</span> -->
                    </label>
                    <Dropdown id="componentname" disabled v-model="edit.componentname" :options="componentList" optionLabel="label"
                        placeholder="Select ..." appendTo="body"></Dropdown>
                    <!-- <small class="p-invalid p-error" v-if="v$.edit.componentname.$error">{{
                        v$.edit.componentname.$errors[0].$message
                    }}</small> -->
                </div>
                <!-- <div class="p-field p-col-12 p-md-6">
                    <label for="freecount">
                        Free Count
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <InputText id="freecount" class="p-text-capitalize" v-model.trim="edit.freecount" required="true"
                        @keypress="onlyNumber" maxlength="8" autofocus
                        :class="{ 'p-invalid': submitted && !edit.freecount }" />
                    <small class="p-invalid p-error" v-if="v$.edit.freecount.$error">{{
                        v$.edit.freecount.$errors[0].$message
                    }}</small>
                </div> -->
                <div class="p-field p-col-12 p-md-6">
                    <label for="extracharges">
                        Extra Charges
                        <span class="p-invalid p-error">*</span>
                    </label>
                    <InputText id="extracharges" class="p-text-capitalize" v-model.trim="edit.extracharges" required="true"
                        @keypress="onlyNumber" maxlength="8" autofocus
                        :class="{ 'p-invalid': submitted && !edit.extracharges }" />
                    <small class="p-invalid p-error" v-if="v$.edit.extracharges.$error">{{
                        v$.edit.extracharges.$errors[0].$message
                    }}</small>
                </div>
            </div>
        </div>
        <template #footer>
            <div>
                <Button v-if="!showLoader" label="Submit" icon="pi pi-check" class="p-button-text p-ml-auto"
                    @click="updatePlanComponent" style="width: 90px"></Button>
                <Button v-show="showLoader" icon="pi pi-spin pi-spinner indigo-color" class="p-button-text p-ml-auto"
                    style="width: 90px"></Button>
            </div>
        </template>
    </Dialog>
    <!-- edit client dialog end here -->
</template>
<script>
import ApiService from '../../service/ApiService';
import useValidate from '@vuelidate/core';
import { useRoute } from 'vue-router';
import { required, helpers, numeric, maxLength, minLength } from '@vuelidate/validators';
export default {
    inject: ['global'],
    data() {
        return {
            v$: useValidate(),
            plancomponentlist: [],
            page_no: 0,
            totalRecords: 0,
            // componentstatus: '',
            partyname: '',
            partyList: '',
            loading: false,
            addComponentStatus: false,
            editComponentStatus: false,
            add: {
                componentname: '',
                freecount: 0,
                extracharges: '',
                componentstatus: '',
            },
            edit: {
                component_id: '',
                component_name: '',
                componentname: '',
                freecount: 0,
                extracharges: '',
                componentstatus: '',
            },
            componentList: [],
            showLoader: false,
            submitted: false,
            row_id: null,
            plan_id: null,
            plan_name: null,
            planComponentData: [],
            plan_exit_id: '',
            plan_exit_label: '',
            routeParam: '',
            component_name: '',
        };
    },
    ApiService: null,
    interval: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        const route = useRoute();
        this.routeParam = route.params.planrowId;
        this.getplancomponents({ plan_id: this.routeParam });
        this.getComponentName({ plan_id: this.routeParam });
        this.plan_exit_label = localStorage.globalexitedPlanLabel;
        this.plan_exit_id = localStorage.globalexitedPlanId;
    },
    validations() {
        return {
            add: {
                componentname: { required: helpers.withMessage('Please select component', required) },
                // freecount: { required: helpers.withMessage('Please enter free count', required), numeric: helpers.withMessage('Only numbers are allowed', numeric), maxLength: maxLength(10) },
                extracharges: { required: helpers.withMessage('Please enter extra charges', required), numeric: helpers.withMessage('Only numbers are allowed', numeric), minLength: minLength(3), maxLength: maxLength(10) },
                componentstatus: { required: helpers.withMessage('Please select type', required) },
            },
            edit: {
                // freecount: { required: helpers.withMessage('Please enter free count', required), numeric: helpers.withMessage('Only numbers are allowed', numeric), maxLength: maxLength(10) },
                extracharges: { required: helpers.withMessage('Please enter extra charges', required), numeric: helpers.withMessage('Only numbers are allowed', numeric), minLength: minLength(3), maxLength: maxLength(10) },
                componentstatus: { required: helpers.withMessage('Please select type', required) },
            },

        };
    },
    methods: {
        getComponentName(ev) {
            this.ApiService.getComponentName(ev).then((data) => {
                if (data.status == 200) {
                    this.componentList = data.data;
                } else {
                    this.componentList = '';
                }
            });
        },
        getplancomponents(ev) {
            this.loading = true;
            this.ApiService.getplancomponents(ev).then((data) => {
                if (data.status == 200) {
                    this.plancomponentlist = data.data;
                    this.totalRecords = data.count;
                    this.loading = false;
                } else {
                    this.plancomponentlist = '';
                    this.totalRecords = 0;
                    this.loading = false;
                }
            });
        },

        onPage(event) {
            this.loading = true;
            this.page_no = event.page;
            this.getplancomponents({ page_no: event.page , plan_id: this.routeParam });
        },
        onlyNumber($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
                $event.preventDefault();
            }
        },
        addComponentDialogOpen() {
            this.addComponentStatus = true;
            this.submitted = false;
            this.add.componentstatus = 2;
            this.add.componentname = '';
            this.add.freecount = 0;
            this.add.extracharges = '';
            this.componentList = [];
            this.getComponentName({ plan_id: this.routeParam });

        },
        addPlanComponent() {
            this.submitted = true;
            this.errormsg = '';
            let fields = {};
            fields["bc2"] = this.plan_exit_id;
            fields["bc3"] = this.plan_exit_label;
            fields["component_apply_for"] = this.add.componentstatus;
            fields['bc4'] = this.add.componentname.value;
            fields['bc5'] = this.add.componentname.label;
            fields["bc6"] = this.add.freecount;
            fields["bc9"] = this.add.extracharges;
            this.v$.add.$validate();
            if (!this.v$.add.$error) {
                this.loading = true;
                this.showLoader = true;
                this.ApiService.createPlanConfig(fields).then((items) => {
                    if (items.status == 200) {
                        this.loading = false;
                        this.showLoader = false;
                        var successMsg = items.message;
                        this.addComponentStatus = false;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                        this.getplancomponents({ plan_id: this.routeParam });
                        this.add.componentstatus = '';
                        this.add.componentname = '';
                        this.add.freecount = 0;
                        this.add.extracharges = '';
                    } else {
                        this.loading = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        editComponentDialogOpen(e) {
            // this.componentList=[];
            this.editComponentStatus = true;
            this.submitted = false;
            this.row_id = e.bc1;
            this.edit.componentstatus = 2;
            this.edit.component_name = e.component_name;
            this.edit.component_id = e.component_id;
            this.edit.freecount = e.included_free_value;
            this.edit.extracharges = e.additional_rate;
            this.plan_id = e.plan_id;
            this.plan_name = e.plan_name;
            this.allow_to_take_advance_rental = e.allow_to_take_advance_rental;
            this.component_used_for = e.component_used_for;
            this.component_type = e.component_type;
            this.allow_advance_rental = e.allow_advance_rental;
            this.component_print_name = e.component_print_name;
            this.component_invoice_name = e.component_invoice_name;
            this.component_code = e.component_code;
            this.component_charge_type = e.component_charge_type;
            this.componentList.push({ value: e.component_id, label: e.component_name });
            let componentListId = this.componentList.filter(function (item) {
                return item.value == e.component_id;
            });
             if (componentListId.length > 0) {
                this.edit.componentname = componentListId[0];
            }

            // this.getComponentName({ plan_id: this.routeParam });

        },
        updatePlanComponent() {
            this.submitted = true;
            this.errormsg = '';
            let field = {};
            field["bc1"] = this.row_id;
            field["plan_id"] = this.plan_id;
            field["plan_name"] = this.plan_name;
            field["component_apply_for"] = this.edit.componentstatus;
            field["bc4"] = this.edit.componentname.value;
            field["bc5"] = this.edit.componentname.label;
            field["bc6"] = this.edit.freecount;
            field["bc9"] = this.edit.extracharges;
            field["bc10"] = this.component_code;
            field["allow_to_take_advance_rental"] = this.allow_to_take_advance_rental;
            field["component_used_for"] = this.component_used_for;
            field["bc7"] = this.component_type;
            field["bc11"] = this.allow_advance_rental;
            field["bc12"] = this.component_print_name;
            field["bc13"] = this.component_invoice_name;
            field["bc8"] = this.component_charge_type;

            this.v$.edit.$validate();

            if (!this.v$.edit.$error) {

                this.loading = true;
                this.showLoader = true;
                this.ApiService.doPlanConfigUpdate({ planComponentData: field }).then((items) => {
                    if (items.status == 200) {
                        this.loading = false;
                        this.showLoader = false;
                        var successMsg = items.message;
                        this.editComponentStatus = false;
                        this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                        // this.getplancomponents();
                        this.componentList.pop();
                        this.getplancomponents({ plan_id: this.routeParam });
                        // this.getComponentName({ plan_id: this.routeParam });

                    } else {

                        this.componentList.pop();
                        this.loading = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },
        goToPlanList() {
            this.$router.push({ path: '/billing/plan' });
        },
        formatCurrency(value) {
            return value.toLocaleString({ style: 'currency', currency: 'INR' });
        },
    },
};
</script>
<style scoped lang="scss"></style>
